.sidebar {
  height: 100vh;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  flex: 0.2;
  min-width: 230px;
  background-color: #040404;
}

.sidebar > hr {
  border: 1px solid #282828;
  width: 90%;
  margin: 10px auto;
}

.sidebar__logo {
  height: 100px;
  width: auto;
  padding: 10px;
  margin: auto;
}

.sidebar__title {
  margin-left: 5px;
  padding: 5px;
  font-size: 12px;
}
